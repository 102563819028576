import React, { useContext, useEffect } from "react";
import useForm from "../../hooks/useForm";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { cloneDeep } from "lodash";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import PlanPill from "../../components/PlanPill/PlanPill";

const initErrorForm = {
  theme: ["required"],
};

const EditDialog = ({ open, handleClose, planId, initForm, isCorporate }) => {
  const [updatePlan] = useMutation(UPDATE_PLAN);
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { form, formErrors, handleChange, handleChangeDate, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleChangeUseDepartmentTheme = () => {
    const clonedForm = cloneDeep(form);
    clonedForm.useDepartmentTheme = !form.useDepartmentTheme;
    resetForm(clonedForm);
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const { theme, useDepartmentTheme, color, shortName } = form;
    const ok = await updatePlan({
      variables: {
        id: planId,
        theme,
        useDepartmentTheme,
        color,
        shortName,
      },
    });

    if (ok) {
      snack("Updated multi-year plan details");
      requestFetch();
      handleClose();
    }
  };

  useEffect(() => {
    resetForm(initForm);
  }, [initForm]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>Edit Multi-Year Department</DialogTitle>
      <DialogContent>
        <TextField
          label="Department Theme"
          name="theme"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.theme || ""}
          onChange={handleChange}
          helperText={formErrors.theme}
          error={Boolean(formErrors.theme)}
          InputProps={
            isCorporate
              ? {}
              : {
                  endAdornment: (
                    <Button
                      variant={form.useDepartmentTheme ? "contained" : "outlined"}
                      onClick={handleChangeUseDepartmentTheme}
                      color="primary"
                      disableElevation
                    >
                      {form.useDepartmentTheme ? "Enabled" : "Disabled"}
                    </Button>
                  ),
                }
          }
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Short Name"
            name="shortName"
            variant="outlined"
            margin="normal"
            value={(form.shortName || "").toUpperCase()}
            onChange={handleChange}
            disabled={isCorporate}
            fullWidth
          />
          <TextField
            type="color"
            onChange={handleChange}
            value={form.color || "#000000"}
            name="color"
            helperText={formErrors.color}
            error={Boolean(formErrors.color)}
            label="Color"
            margin="normal"
            variant="outlined"
            fullWidth
            style={{ marginLeft: "0.5rem" }}
          />
          <PlanPill plan={{ departmentName: form.departmentName, color: form.color, shortName: form.shortName }} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

const UPDATE_PLAN = gql`
  mutation ($id: ID!, $theme: String, $useDepartmentTheme: Boolean, $color: String, $shortName: String) {
    updateSinglePlan(id: $id, theme: $theme, useDepartmentTheme: $useDepartmentTheme, color: $color, shortName: $shortName)
  }
`;
