import React, { useContext, useEffect } from "react";
import _ from "lodash";
import styles from "./TheCore.module.scss";
import useForm from "../../hooks/useForm";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { isNil, cloneDeep } from "lodash";
import { SnackbarContext } from "../../context/snackbarContext";
import { FetchContext } from "../../context/fetchContext";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, Divider, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import PlanPill from "../../components/PlanPill/PlanPill";

const initErrorForm = {};

const EditDialog = ({ open, handleClose, initForm }) => {
  const { snack } = useContext(SnackbarContext);
  const { requestFetch } = useContext(FetchContext);
  const [updatePlan, { error, reset }] = useMutation(UPDATE_PLAN);
  const { form, formErrors, handleChange, handleChangeDate, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleChangeUseDepartmentTheme = (path) => () => {
    const clonedForm = cloneDeep(form);
    _.set(clonedForm, path, !_.get(form, path));
    resetForm(clonedForm);
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    let { departmentName, oneYear, threeYear, color, shortName } = form;

    let variables = {
      oneYear,
      threeYear,
      color,
      shortName,
    };
    if (_.get(initForm, "departmentName") !== "Corporate") {
      variables = { ...variables, departmentName };
    }

    const ok = await updatePlan({
      variables,
    });

    if (ok.data.updatePlan) {
      requestFetch();
      snack(`Successfully updated ${form.departmentName} department`);
      handleClose();
    }
  };

  useEffect(() => {
    resetForm(initForm);
  }, [initForm]);

  const isCorporate = initForm.departmentName === "Corporate";

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>Edit Department</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextField
              label="Department Name"
              name="departmentName"
              fullWidth
              variant="outlined"
              margin="normal"
              value={_.get(form, "departmentName", "")}
              onChange={handleChange}
              disabled={isCorporate}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Label"
                  name="shortName"
                  variant="outlined"
                  margin="normal"
                  value={(_.get(form, "shortName") || "").toUpperCase()}
                  onChange={handleChange}
                  disabled={isCorporate}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    type="color"
                    onChange={handleChange}
                    value={_.get(form, "color") || "#000000"}
                    name="color"
                    helperText={formErrors.color}
                    error={Boolean(formErrors.color)}
                    label="Color"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <PlanPill
                    plan={{
                      departmentName: form.departmentName,
                      color: _.get(form, "color"),
                      shortName: _.get(form, "shortName"),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ margin: "1rem 0" }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography>1 Year Properties</Typography>
                <TextField
                  label="Department Theme"
                  name="oneYear.theme"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={_.get(form, "oneYear.theme") || ""}
                  onChange={handleChange}
                  helperText={formErrors.theme}
                  error={Boolean(formErrors.theme)}
                  disabled={isCorporate ? false : !_.get(form, "oneYear.useDepartmentTheme", false)}
                  InputProps={
                    isCorporate
                      ? {}
                      : {
                          endAdornment: (
                            <Button
                              variant={_.get(form, "oneYear.useDepartmentTheme", false) ? "contained" : "outlined"}
                              onClick={handleChangeUseDepartmentTheme("oneYear.useDepartmentTheme")}
                              color="primary"
                              disableElevation
                            >
                              {_.get(form, "oneYear.useDepartmentTheme", false) ? "Enabled" : "Disabled"}
                            </Button>
                          ),
                        }
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Multi-Year Properties</Typography>
                <TextField
                  label="Department Theme"
                  name="threeYear.theme"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={_.get(form, "threeYear.theme") || ""}
                  onChange={handleChange}
                  helperText={formErrors.theme}
                  error={Boolean(formErrors.theme)}
                  disabled={isCorporate ? false : !_.get(form, "threeYear.useDepartmentTheme", false)}
                  InputProps={
                    isCorporate
                      ? {}
                      : {
                          endAdornment: (
                            <Button
                              variant={_.get(form, "threeYear.useDepartmentTheme", false) ? "contained" : "outlined"}
                              onClick={handleChangeUseDepartmentTheme("threeYear.useDepartmentTheme")}
                              color="primary"
                              disableElevation
                            >
                              {_.get(form, "threeYear.useDepartmentTheme", false) ? "Enabled" : "Disabled"}
                            </Button>
                          ),
                        }
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

const UPDATE_PLAN = gql`
  mutation ($departmentName: String, $oneYear: OneYearUpdateData, $threeYear: ThreeYearUpdateData, $color: String, $shortName: String) {
    updatePlan(departmentName: $departmentName, oneYear: $oneYear, threeYear: $threeYear, color: $color, shortName: $shortName)
  }
`;
