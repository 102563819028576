import React, { useState } from "react";
import _ from "lodash";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import styles from "./Metrics.module.scss";
import PlanPill from "../PlanPill/PlanPill";

function WeeklyTargetsList({ open, onClose, weeklyTargets, onSubmit, formWeeklyTarget }) {
  const [selectedWeeklyTarget, setSelectedWeeklyTarget] = useState(formWeeklyTarget ? formWeeklyTarget : null);

  const handleListItemClick = (id) => () => {
    setSelectedWeeklyTarget((prev) => (id === prev ? null : id));
  };

  const handleSubmit = () => {
    onSubmit(selectedWeeklyTarget);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>KPIs</DialogTitle>
      <DialogContent>
        <List>
          {_.isEmpty(weeklyTargets) ? (
            <Typography>No KPIs available</Typography>
          ) : (
            weeklyTargets.map(({ id, value, number, plan }, idx) => (
              <ListItem
                key={id}
                button
                selected={selectedWeeklyTarget === id}
                onClick={handleListItemClick(id)}
                className={styles.metricListItem}
              >
                <ListItemText>
                  {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
                  {number}. {value}
                </ListItemText>
              </ListItem>
            ))
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WeeklyTargetsList;
