import gql from "graphql-tag";
import { METRIC_FIELDS, OBJECTIVE_FIELDS, ROCK_FIELDS, TODO_FIELDS, WEEKLY_TARGET_FIELDS } from "./fragments";

export const GET_OBJECTIVES = gql`
  ${OBJECTIVE_FIELDS}
  query GetObjectives(
    $organization: ID!
    $planId: ID
    $sharedPlanId: ID
    $category: String
    $objectives: [ID!]
    $users: [ID!]
    $statuses: [String]
  ) {
    objectives(
      organization: $organization
      corpPlan: $planId
      sharedPlanId: $sharedPlanId
      category: $category
      objectives: $objectives
      users: $users
      statuses: $statuses
    ) {
      ...ObjectiveFields
    }
  }
`;

export const GET_QUARTER_ROCKS = gql`
  ${ROCK_FIELDS}
  query GetQuarterRocks($organization: ID!, $sharedPlanId: ID, $oneYearCorpPlan: ID) {
    rocks(organization: $organization, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      ...RockFields
      objective {
        id
        value
      }
    }
  }
`;

export const GET_METRICS = gql`
  ${METRIC_FIELDS}
  query GetMetrics($organization: ID!, $planId: ID!, $sharedPlanId: ID, $users: [ID!], $category: String) {
    metrics(organization: $organization, corpPlan: $planId, sharedPlanId: $sharedPlanId, users: $users, category: $category) {
      ...MetricFields
    }
  }
`;

export const GET_WEEKLY_TARGETS = gql`
  ${WEEKLY_TARGET_FIELDS}
  query GetWeeklyTargets($organization: ID!, $sharedPlanId: ID, $oneYearCorpPlan: ID, $user: ID, $users: [ID!]) {
    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan, user: $user, users: $users) {
      ...WeeklyTargetFields
    }
  }
`;

export const GET_TODOS = gql`
  ${TODO_FIELDS}
  query GetTodos(
    $organization: ID!
    $sharedPlanId: ID
    $category: String!
    $oneYearCorpPlan: ID
    $page: Int
    $rowsPerPage: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: String
    $done: Boolean
  ) {
    ...TodoFields
  }
`;

export const GET_ONE_YEAR_METRICS = gql`
  query MetricsDialog_GetOneYearMetrics($organization: ID!, $sharedPlanId: ID) {
    metrics(organization: $organization, category: "quarter", sharedPlanId: $sharedPlanId) {
      id
      value
      number
      measurables {
        value
        reference {
          id
        }
      }
      plan {
        id
        departmentName
        sharedPlanId
        year
        plan {
          id
          year
        }
        color
        shortName
      }
    }
  }
`;

export const GET_THREE_YEAR_METRICS = gql`
  query MetricsDialog_GetThreeYearMetric($organization: ID!, $sharedPlanId: ID) {
    metrics(organization: $organization, category: "year", sharedPlanId: $sharedPlanId) {
      id
      value
      number
      category
      measurables {
        value
        notes {
          id
        }
        reference {
          id
          calculateTotal
          measurables {
            value
          }
        }
        distribution
      }
      plan {
        id
        departmentName
        sharedPlanId
        year
        plan {
          id
          year
        }
        color
        shortName
      }
    }
  }
`;

export const GET_ONE_YEAR_PLAN = gql`
  query MetricsDialog_GetOneYearPlan($organization: ID!, $sharedPlanId: ID) {
    plan(organization: $organization, sharedPlanId: $sharedPlanId, category: "1 year", closed: false) {
      id
    }
  }
`;
