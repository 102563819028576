import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, styled } from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import styles from "./Metrics.module.scss";

const defaultDistribution = [0.25, 0.25, 0.25, 0.25];

const Distribution = ({ open, onClose, selectedYear, distribution, handleSubmit }) => {
  const [distributionValues, setDistributionValues] = useState(
    !_.isNil(distribution) && !_.isEmpty(distribution) ? distribution : defaultDistribution
  );
  const handleChange = (e) => {
    const index = e.target.name;
    let newDistributionValues = [...distributionValues];
    newDistributionValues[index] = Number(e.target.value);
    setDistributionValues(newDistributionValues);
  };

  const total = _.round(_.sum(distributionValues), 2);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Quarter Projected Distribution ({selectedYear})</DialogTitle>
      <DialogContent>
        <div className={styles.distributionContainer}>
          {distributionValues.map((value, idx) => (
            <TextField
              key={idx}
              label={`Q${idx + 1}`}
              name={`${idx}`}
              variant="outlined"
              margin="dense"
              value={value}
              type="number"
              onChange={handleChange}
            />
          ))}
        </div>
        <div className={styles.distributionTotal} style={{ color: total === 1 ? "green" : "red" }}>
          Total: {total}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(distributionValues)} color="primary" disabled={total !== 1}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Distribution;
